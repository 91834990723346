import { PopoverPortal } from "@radix-ui/react-popover";
import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutoFillTypes } from "src/api/types";
import Skeleton from "src/components/Skeleton";
import FormGroup from "src/components/form/FormGroup";
import AutoFillOptions from "src/components/form/Input/AutoFillOptions";
import { Phone } from "src/components/form/Input/phone/phone";
import Icon from "src/components/icon/Icon";
import { Calendar } from "src/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "src/components/ui/popover";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";
import "./input.scss";

interface InputProps {
	type?: string;
	placeholder?: string;
	label?: string;
	value?: any;
	name: string;
	onChange?: (key: string, value: any) => void;
	handleChange?: (e: any) => void;
	handleBlur?: (e: any) => void;
	onFocus?: (e: any) => void;
	onKeyDown?: (e: any) => void;
	onKeyPress?: (e: any) => void;
	error?: any | boolean;
	valid?: string | boolean | number;
	loading?: boolean;
	multiline?: boolean;
	optional?: boolean;
	showPast?: boolean;
	button?: any;
	help?: string;
	autoFill?: AutoFillTypes;
	autoFillQuery?: string;
	autoFocus?: boolean;
	increments?: boolean;
	incrementsPosition?: "default" | "right";
	variant?: "dark";
	className?: string;
	icon?: any;
	noMarginBottom?: boolean;
	disabled?: boolean;
	inputId?: string;
	withTime?: boolean;
	textareaClassName?: string;
	layout: "stacked" | "horizontal";
	step?: any;
	style?: any;
	subLabel?: string | JSX.Element;
}

const Input = ({
	type,
	value,
	placeholder,
	label,
	onChange,
	name,
	handleChange,
	handleBlur,
	onFocus,
	onKeyDown,
	onKeyPress,
	error,
	valid,
	loading,
	multiline,
	showPast,
	button,
	help,
	autoFill,
	autoFillQuery,
	autoFocus,
	increments,
	incrementsPosition = "default",
	variant,
	optional,
	className,
	icon,
	noMarginBottom,
	disabled,
	inputId,
	withTime,
	textareaClassName,
	layout,
	step,
	style,
	subLabel,
}: InputProps) => {
	const [visible, setVisible] = useState(false);
	const { t } = useTranslation("form", {
		keyPrefix: "input",
	});
	const { isPhone } = useWindowSize();
	const ref = useRef(null);
	const input = useRef(null) as any;
	const classes = ["input"];
	const [focused, onFocusChange] = useState(false) as any;
	if (error) classes.push("input_has-error");
	if (valid && !error) classes.push("input_is-valid");
	if (type) classes.push(`input_${type}`);
	if (variant) classes.push(`input_${variant}`);
	if (disabled) classes.push(`input_disabled`);
	if (increments && incrementsPosition !== "right")
		classes.push(`input_increments`);

	if (incrementsPosition === "right") {
		classes.push(`input_increments-right`);
	}

	if (button) classes.push("input-has-button");
	if (autoFill) classes.push("input-has-auto-fill");
	if (className) classes.push(className);
	// if (icon || type === "telephone") {
	if (icon) {
		classes.push("input-has-prefix");
	}

	let time = null;

	if (type === "date" && withTime) {
		time = value ? moment(value).format("HH:mm") : undefined;
	}

	const getDateInputValue = () => {
		if (!value) return undefined;
		const iso = moment.utc(value, "YYYY-MM-DD").toISOString();
		return iso?.substring(0, 10);
	};
	const setChange = (value: any) => {
		if (onChange) onChange(name, value);
		if (handleChange)
			handleChange({
				target: {
					name,
					value,
				},
			});
	};

	const handleDateChange = (selectedDate: any) => {
		if (!selectedDate) return;
		let currentValue = value ? moment(value) : moment();
		currentValue.set({
			year: moment(selectedDate).year(),
			month: moment(selectedDate).month(),
			date: moment(selectedDate).date(),
		});

		setChange(currentValue.format("YYYY-MM-DD HH:mm:ss"));
	};

	const handleIncrements = (type: "min" | "plus") => {
		value = Number(value);
		if (type === "min" && value > 1) {
			value = value - 1;
		} else if (type === "plus") {
			value = value + 1;
		}
		setChange(value);
	};

	if (type === "date") {
		return (
			<>
				<FormGroup
					{...{
						ref,
						label,
						subLabel,
						valid,
						help,
						error,
						type,
						loading,
						disabled,
						noMarginBottom,
						optional,
						layout,
					}}
					className={classes.join(" ")}
				>
					<div className="flex flex-col flex-1 gap-2">
						<div className="flex flex-1 gap-2">
							<div className="flex-1">
								<Popover
									open={focused}
									onOpenChange={onFocusChange}
								>
									<PopoverTrigger asChild>
										<div
											className={cn(
												"pl-2 pr-4 border border-border rounded-[6px] w-full transition-all flex items-center h-[42px]",
												!value
													? "text-placeholder"
													: "text-background-foreground",
												disabled &&
													"opacity-40 cursor-not-allowed"
											)}
										>
											<Icon
												onClick={() =>
													onFocusChange(!focused)
												}
												icon={Icon.getFontAwesome(
													"fa-calendar",
													"far"
												)}
												iconFontSize={14}
												className="cursor-pointer"
											/>
											{!isPhone ? (
												<input
													style={{ paddingLeft: 8 }}
													className={cn(
														"hide-date-icon bg-transparent flex-1",
														!value &&
															"text-placeholder"
													)}
													onClick={(e) =>
														e.stopPropagation()
													}
													ref={input}
													type="date"
													value={getDateInputValue()}
													onChange={(e) => {
														handleDateChange(
															e.target.value
														);
													}}
												/>
											) : (
												<span
													className={cn(
														"leading-full",
														!value &&
															"text-placeholder"
													)}
												>
													{value
														? moment(value).format(
																"DD-MM-YYYY"
														  )
														: t("date-placeholder")}
												</span>
											)}
										</div>
									</PopoverTrigger>
									<PopoverPortal>
										<PopoverContent
											className="p-2"
											align="start"
										>
											<Calendar
												mode="single"
												selected={
													value
														? moment(value).toDate()
														: undefined
												}
												className="p-0"
												onSelect={(value) => {
													onFocusChange(false);
													handleDateChange(value);
												}}
											/>
										</PopoverContent>
									</PopoverPortal>
								</Popover>
							</div>
							{withTime && (
								<input
									type="time"
									className="max-w-[120px] placholder:text-placeholder bg-accent text-accent-foreground border-border border flex w-full rounded-md overflow-hidden"
									value={time || ""}
									placeholder="Tijd"
									onChange={(e: any) => {
										if (disabled) return;
										let date = value
											? moment(value)
											: moment();
										let _value = e.target.value;

										const [hours, minutes] =
											_value.split(":");
										date.set({
											hours,
											minutes,
										});

										setChange(date);
									}}
								/>
							)}
						</div>
						{optional && (
							<small
								onClick={() => {
									const value = null;
									if (onChange) onChange(name, value);
									if (handleChange)
										handleChange({
											target: {
												name,
												value,
											},
										});
								}}
								className="cursor-pointer transition-all hover:opacity-90"
							>
								{t("clear")}
							</small>
						)}
					</div>
				</FormGroup>
			</>
		);
	}

	const handleFocusInput = () => {
		if (input?.current) {
			input.current?.focus();
		}
	};

	const renderInput = () => {
		if (type === "telephone") {
			return (
				<Phone
					defaultCountry="nl"
					{...{ placeholder }}
					className="border-border border flex w-full rounded-md overflow-hidden"
					value={value}
					onChange={(event) => {
						if (onChange) onChange(name, event?.target?.value);
						if (handleChange)
							handleChange({
								target: {
									value: event.target.value,
									name: name,
								},
							});
					}}
					onBlur={handleBlur}
				>
					<Phone.Country
						search={{
							placeholder: t("telephone.search.placeholder"),
						}}
						className="bg-transparent"
					/>
					<Phone.Number
						className="placeholder:text-placeholder bg-transparent flex-1"
						onBlur={handleBlur}
						{...{
							placeholder,
							name,
							onFocus,
							onKeyDown,
							onKeyPress,
							autoFocus,
							step,
							style,
						}}
					/>
				</Phone>
			);
		}
		return (
			<input
				id={inputId}
				onChange={(e: any) => {
					if (disabled) return;
					if (type === "number") {
						const str = e.target.value;
						let transformed = str
							? str.toString().replace(/B(?=(d{3})+(?!d))/g, ",")
							: str;

						if (transformed) {
							if (!isNaN(transformed)) {
								transformed = parseFloat(transformed);
							}
							e.target.value = transformed;
							if (onChange) {
								onChange(name, transformed);
							}

							if (handleChange) {
								handleChange({
									...e,
									target: {
										...e.target,
										name,
										value: transformed,
									},
								});
							}
							if (onChange || handleChange) {
								return;
							}
						}
					}
					if (onChange && type === "price") {
						onChange(name, parseFloat(e.target.value));
					}

					if (onChange && type !== "price")
						onChange(name, e.target.value);
					if (handleChange) handleChange(e);
				}}
				className={cn(
					"placeholder:text-placeholder dark:bg-accent bg-transparent text-accent-foreground border-border border flex-1",
					disabled &&
						"cursor-not-allowed pointer-events-none bg-border border-accent"
				)}
				onBlur={handleBlur}
				placeholder={placeholder || label}
				{...{
					name,
					onFocus,
					onKeyDown,
					onKeyPress,
					autoFocus,
					step,
					style,
				}}
				ref={input}
				type={
					type === "price"
						? "number"
						: type === "password" && visible
						? "text"
						: type
				}
				{...(type === "time"
					? {
							pattern: "[0-2][0-9]:[0-5][0-9]",
							step: 15 * 60,
							defaultValue: value || "",
					  }
					: { value, defaultValue: value })}
			/>
		);
	};

	const wrapWithAutofill = (children: JSX.Element) => {
		if (autoFill) {
			return (
				<AutoFillOptions
					{...{ value }}
					// query={autoFillQuery || ""}
					onSelect={(s) => {
						if (onChange) {
							onChange(name, s);
						}
						if (handleChange) {
							handleChange({
								target: {
									name,
									value: s,
								},
							});
						}
					}}
					type={autoFill}
				>
					{children}
				</AutoFillOptions>
			);
		}
		return children;
	};

	return wrapWithAutofill(
		<FormGroup
			{...{
				ref,
				label,
				subLabel,
				valid,
				help,
				error,
				type,
				loading,
				name,
				optional,
				noMarginBottom,
				disabled,
				layout,
			}}
			className={classes.join(" ")}
		>
			{icon && (
				<div
					onClick={handleFocusInput}
					className="icon-prefix -mr-2 bg-accent text-accent-foreground border-border border-l border-t border-b"
				>
					{icon}
				</div>
			)}

			{type === "price" && (
				<div
					onClick={handleFocusInput}
					className="price-prefix bg-accent text-accent-foreground border-border border-l border-t border-b"
				>
					<p>&euro;</p>
				</div>
			)}

			{increments &&
				type === "number" &&
				!disabled &&
				incrementsPosition === "default" && (
					<NumberControl
						className="rounded-l"
						onClick={() => handleIncrements("min")}
					>
						<i className="fal fa-minus"></i>
					</NumberControl>
				)}
			{multiline ? (
				<textarea
					id={inputId}
					rows={2}
					onChange={(e) => {
						if (disabled) return;
						if (onChange) onChange(name, e.target.value);
						if (handleChange) handleChange(e);
					}}
					onBlur={handleBlur}
					placeholder={placeholder || label}
					ref={input}
					className={cn(
						"placeholder:text-placeholder flex-1 bg-transparent dark:bg-accent text-accent-foreground border-border border min-h-[120px]",
						textareaClassName
					)}
					{...{ value, name, type, onFocus, onKeyPress }}
				/>
			) : button ? (
				<div className="input-row flex-">
					{renderInput()}
					{button}
				</div>
			) : (
				renderInput()
			)}
			{increments &&
				type === "number" &&
				!disabled &&
				incrementsPosition === "right" && (
					<NumberControl
						className="border-l-0"
						onClick={() => handleIncrements("min")}
					>
						<i className="fal fa-minus"></i>
					</NumberControl>
				)}
			{increments && type === "number" && !disabled && (
				<NumberControl
					className="rounded-r border-r border-l-0"
					onClick={() => handleIncrements("plus")}
				>
					<i className="fal fa-plus"></i>
				</NumberControl>
			)}

			{type === "password" && (
				<div className="absolute right-0 top-0 bottom-0 flex justify-center items-center mr-4">
					<span
						onClick={() => setVisible(!visible)}
						className="cursor-pointer"
					>
						<i
							className={`far ${
								visible ? "fa-eye-slash" : "fa-eye"
							}`}
						></i>
					</span>
				</div>
			)}
		</FormGroup>
	);
};

const Loading = () => {
	return (
		<FormGroup.Loading>
			<Skeleton className="w-full h-[50px]" />
		</FormGroup.Loading>
	);
};

Input.Loading = Loading;

Input.defaultProps = {
	layout: "stacked",
};

interface NumberControlProps {
	children: JSX.Element;
	onClick: () => void;
	className?: string;
}

const NumberControl = ({
	onClick,
	children,
	className,
}: NumberControlProps) => {
	return (
		<div
			{...{ onClick }}
			className={cn(
				"bg-accent h-[42px] w-[42px] flex justify-center items-center text-accent-foreground border-border border-l border-t border-b cursor-pointer transition-all hover:bg-border",
				className
			)}
		>
			<span>{children}</span>
		</div>
	);
};

export default Input;
