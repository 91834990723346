import { Navigate, matchPath, useLocation } from "react-router-dom";
import { ApiPermission, ApiTenantModule } from "src/api/types";
import ModalView, { ModalElementProps } from "src/components/layout/ModalView";
import usePermissions from "src/hooks/api/services/auth/usePermissions";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import useSubscription from "src/hooks/selectors/useSubscription";
import { getRedirectSearch, getRedirectUrl } from "src/hooks/useRedirectUrl";
import BlockedAccount from "src/views/auth/BlockedAccount";
import NoPermissionView from "src/views/auth/NoPermissionView";
import NotFoundView from "src/views/auth/NotFoundView";

interface ProtectedRouteProps {
	type?: "student" | "company";
	guard?: ApiTenantModule[];
	variant?: "modal" | "on-top";
	children?: any;
	modal?: ModalElementProps;
	permission?: ApiPermission | ApiPermission[];
}

const ProtectedRoute = ({
	type,
	guard,
	variant,
	children,
	modal,
	permission,
}: ProtectedRouteProps) => {
	const auth = useAuth();
	const location: any = useLocation();
	const permissions = usePermissions();
	const languageUrl = getRedirectUrl("/language", location.pathname);
	const subscription = useSubscription();
	const isChargeback = subscription?.payment?.status === "chargeback";
	const refreshPath = `/payments/${subscription?.payment?.hid}/refresh`;
	const BLOCKED_STATUSES = ["terminated", "blocked", "suspended"];
	const { onboarding_status } = auth;
	const { tenant } = useTenant();
	const modules = tenant?.modules || [];

	if (!auth?.id) {
		return (
			<Navigate
				to={{
					pathname: "/login",
					search: `?${getRedirectSearch(location.pathname)}`,
				}}
			/>
		);
	}

	if (auth.id && BLOCKED_STATUSES.includes(onboarding_status)) {
		return <BlockedAccount />;
	}

	if (!auth.type && !matchPath("/setup/type", location.pathname)) {
		return <Navigate to={"/setup/type"} />;
	}

	if (auth.type && matchPath("/setup/type", location.pathname)) {
		return <Navigate to={"/dashboard"} />;
	}

	if (
		tenant.settings.features.waitlist &&
		!auth.is_waitlisted &&
		auth.type &&
		!matchPath("/waitlist", location.pathname)
	) {
		return <Navigate to="/waitlist" />;
	}

	if (
		auth.type === "company" &&
		isChargeback &&
		!matchPath("/payments/:id/refresh", location.pathname)
	) {
		return <Navigate to={refreshPath} />;
	}

	if (
		auth?.id &&
		!tenant.has_access &&
		!matchPath("/enable-access", location.pathname)
	) {
		return <Navigate to="/enable-access" />;
	}

	if (
		auth.id &&
		!auth.activated_at &&
		auth.company?.id &&
		!matchPath("/not-activated", location.pathname)
	) {
		return <Navigate to="/not-activated" />;
	}

	if (
		auth.id &&
		!auth?.language &&
		!matchPath("/language", location.pathname)
	) {
		return <Navigate to={languageUrl} />;
	}

	if (
		auth.id &&
		!auth?.links?.client &&
		(auth.type === "student" ||
			(auth.type === "company" && auth.company_id)) &&
		!matchPath("/setup/client", location.pathname)
	) {
		return <Navigate to="/setup/client" />;
	}

	if (
		auth.type &&
		auth?.onboarding?.missing?.length &&
		!auth.onboarding.is_completed &&
		modules.includes("extern-task") &&
		auth?.language &&
		!location.pathname.startsWith("/onboarding")
	) {
		return <Navigate to={"/onboarding"} />;
	}

	const isNotAllowed = type && type !== auth.type;
	if (isNotAllowed) {
		return <Navigate to="/dashboard" />;
	}

	if (
		guard?.length &&
		!guard.every((middleware: any) => modules.includes(middleware))
	) {
		return <NotFoundView />;
	}

	if (permission && !permissions.has(permission)) {
		return <NoPermissionView />;
	}

	if (variant === "modal" && modal) {
		return (
			<ModalView {...{ modal }} visible={true}>
				{children}
			</ModalView>
		);
	}

	if (variant === "on-top") {
		return <>{children}</>;
	}

	return children;
};

ProtectedRoute.defaultProps = {
	guard: [],
};

export default ProtectedRoute;
