import AuthLayout, {
	AuthLayoutContainer,
} from "src/components/layout/auth-layout/AuthLayout";
import SubscriptionPlans from "src/components/subscription/SubscriptionPlans";

function SignupCompanyView() {
	return (
		<AuthLayout variant="flat" actions={["contact-sales"]}>
			<AuthLayoutContainer>
				<SubscriptionPlans
					getPlanCheckoutUrl={(plan, interval) =>
						`/signup/company/subscription/${plan.slug}?interval=${interval}`
					}
				/>
			</AuthLayoutContainer>
		</AuthLayout>
	);
}

export default SignupCompanyView;
