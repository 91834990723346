import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckoutType } from "src/api/types";
import Button from "src/components/Button";
import Skeleton from "src/components/Skeleton";
import useAuth from "src/hooks/selectors/useAuth";
import useIssuer from "src/hooks/selectors/useIssuer";
import CheckoutPaymentMethodItem from "./CheckoutPaymentMethodItem";

interface CheckoutPaymentMethodsProps {
	onSubmit: (_type: CheckoutType, issuer?: any) => any;
	pending?: boolean | string;
	button?: {
		className?: string;
		type?: string;
		label?: string;
	};
	itemBg?: string;
	className?: string;
	loading?: boolean;
	methods: CheckoutType[];
	disclaimer?: string;
	variant: "light" | "dark";
	type?: string;
	extra?: any;
}

const CheckoutPaymentMethods = ({
	onSubmit,
	pending,
	button,
	className,
	itemBg,
	loading,
	methods,
	disclaimer,
	variant,
	type,
	extra,
}: CheckoutPaymentMethodsProps) => {
	const { t } = useTranslation(["payments"]);
	const [selected, setSelected] = useState<CheckoutType>();
	const [issuer] = useState<any>();
	const auth = useAuth();
	const permissions = auth?.company?.permissions || [];
	const { ideal, fetchIssuers } = useIssuer();
	const idealItems = useRef() as any;

	const getIsButtonDisabled = () => {
		// if (selected === "ideal" && !issuer) return true;
		return !selected;
	};

	useEffect(() => {
		if (!ideal?.length) {
			fetchIssuers();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (selected === "ideal") {
			setTimeout(() => {
				idealItems.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
			}, 200);
		}
	}, [selected]);

	if (loading) {
		return <CheckoutPaymentMethodsLoading bg={itemBg} />;
	}

	return (
		<div {...{ className }}>
			<div ref={idealItems}></div>
			<strong className="mb-4 block">
				{t("checkout-payment-methods.title")}
			</strong>
			<div className="flex flex-col gap-4 mb-8">
				{methods.includes("ideal") && (
					<>
						<CheckoutPaymentMethodItem
							active={selected === "ideal"}
							label={t(`methods.ideal`)}
							bg={variant === "dark" ? "dark" : "white"}
							icon="fab fa-ideal"
							onClick={() => {
								if (!pending) {
									setSelected(
										selected !== "ideal"
											? "ideal"
											: undefined
									);
								}
							}}
						/>
						{/* {selected === "ideal" && (
							<div className="flex flex-col gap-2 overflow-y-auto max-h-[40vh] hide-default-scrollbar">
								{(ideal || []).map((item: ApiIssuer) => (
									<IdealPaymentMethod
										key={item.bic}
										{...{ variant }}
										type="circle"
										active={issuer === item.bic}
										onClick={() =>
											setIssuer(
												issuer !== item.bic
													? item.bic
													: undefined
											)
										}
										{...item}
									/>
								))}
							</div>
						)} */}
					</>
				)}
				{permissions.includes("pay_with_invoice") &&
					methods.includes("invoice") && (
						<CheckoutPaymentMethodItem
							active={selected === "invoice"}
							label={t(`methods.invoice`)}
							bg={variant === "dark" ? "dark" : "white"}
							icon="fal fa-file"
							onClick={() => {
								if (!pending) {
									setSelected("invoice");
								}
							}}
						/>
					)}

				{permissions.includes("pay_with_afterpay") &&
					methods.includes("afterpay") && (
						<CheckoutPaymentMethodItem
							active={selected === "afterpay"}
							label={t(`methods.afterpay`)}
							bg={variant === "dark" ? "dark" : "white"}
							icon="fal fa-file"
							onClick={() => {
								if (!pending) {
									setSelected("afterpay");
								}
							}}
						/>
					)}
				{auth?.company.statuses.has_active_mandate &&
					methods.includes("mandate") && (
						<CheckoutPaymentMethodItem
							bg={variant === "dark" ? "dark" : "white"}
							active={selected === "mandate"}
							label={t(`methods.mandate`)}
							icon="fal fa-credit-card"
							subLabel={
								type === "registration" &&
								t(
									"checkout-payment-methods.mandate-disclaimer",
									{
										date: moment()
											.add(8, "days")
											.format("DD-MM-YYYY"),
									}
								)
							}
							onClick={() => {
								if (!pending) {
									setSelected("mandate");
								}
							}}
						/>
					)}
				{disclaimer && <small>{disclaimer}</small>}
			</div>

			{extra}

			<div className={`bottom-0 mt-12 rounded-md`}>
				<div className="flex justify-center rounded-full">
					<Button
						type={(button?.type as any) || "dark"}
						disabled={getIsButtonDisabled()}
						loading={pending !== undefined}
						onClick={() => selected && onSubmit(selected, issuer)}
						className={`${button?.className || ""} w-full`}
					>
						{button?.label ||
							t("checkout-payment-methods.checkout")}
					</Button>
				</div>
			</div>
		</div>
	);
};

CheckoutPaymentMethods.defaultProps = {
	methods: ["ideal", "mandate"],
	variant: "dark",
};

export const CheckoutPaymentMethodsLoading = ({
	className,
	bg,
}: {
	className?: string;
	bg?: string;
}) => (
	<div className={`${className || ""} md:p-8`}>
		<Skeleton className={`mb-4 h-6 w-1/4 bg-${bg || "dark"}`}></Skeleton>
		<div className="flex flex-col gap-4 mb-8">
			{new Array(2).fill("").map((_, index) => (
				<Skeleton
					key={`method-${index}`}
					className={`max-w-2/4 h-12 bg-${bg || "dark"}`}
				/>
			))}
		</div>
		<div className="sticky bottom-0 p-4 md:p-0 md:relative">
			<div className="shadow-lg md:shadow-none flex justify-center rounded-full">
				<Skeleton
					className={`flex-1 max-w-3/4 h-12 bg-${bg || "dark"}`}
				></Skeleton>
			</div>
		</div>
	</div>
);

export default CheckoutPaymentMethods;
