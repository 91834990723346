import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BackButton from "src/components/BackButton";
import Chat from "src/components/chat/Chat";
import ChatProvider from "src/components/chat/ChatProvider";
import OfferChat from "src/components/chat/chats/OfferChat";
import TaskChat from "src/components/chat/chats/TaskChat";
import Layout from "src/components/layout/Layout";
import InboxList from "src/components/lists/InboxList";
import SubscriptionFeatureMiddleware from "src/components/middlewares/SubscriptionFeatureMiddleware";
import useAuth from "src/hooks/selectors/useAuth";
import useSubscription from "src/hooks/selectors/useSubscription";
import useWindowSize from "src/hooks/useWindowSize";

const DashboardInboxView = () => {
	const { t } = useTranslation("inbox-view", {
		keyPrefix: "inbox-view",
	});
	const params = useParams() as any;
	const size = useWindowSize();
	const auth = useAuth();
	const subscription = useSubscription();

	if (auth.type === "company" && !subscription?.id) {
		return (
			<Layout
				title={t("title")}
				breadcrumb={[
					{
						label: t("title"),
						to: "/inbox",
					},
				]}
			>
				<SubscriptionFeatureMiddleware plan="all" />
			</Layout>
		);
	}

	return (
		<Layout
			title={t("title")}
			breadcrumb={[
				{
					label: t("title"),
					to: "/inbox",
				},
			]}
		>
			<div className="flex flex-col md:grid md:grid-cols-[320px,1fr] gap-8 flex-1">
				{(!size.isTablet || !params.id) && (
					<InboxList variant="light" />
				)}

				{!params.id && !size.isTablet && (
					<div className="col-span-1 flex flex-1 justify-center items-center flex-col">
						<strong>{t("select")}</strong>
					</div>
				)}
				{params?.id && (
					<ChatProvider id={params.id}>
						{(loading, data) => {
							if (loading) {
								return <Chat.Loading className="flex-1" />;
							}

							if (!data || !data?.registration) {
								return (
									<div className="flex flex-1 justify-center items-center flex-col">
										<strong>{t("not-found")}</strong>
									</div>
								);
							}
							const { task, registration, offer, type } = data;

							return (
								<div className="flex flex-1 flex-col gap-3">
									<div className="h-10 flex md:hidden">
										<BackButton
											to={`/inbox`}
											label={t("back")}
										/>
									</div>
									{type === "task" &&
										task &&
										registration && (
											<TaskChat
												{...{ task, registration }}
											/>
										)}
									{type === "offer" && offer && (
										<OfferChat
											variant="modal"
											{...{ offer, registration }}
										/>
									)}
								</div>
							);
						}}
					</ChatProvider>
				)}
			</div>
		</Layout>
	);
};

export default DashboardInboxView;
