import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Card from "src/components/Card";
import BankCard from "src/components/cards/BankCard";
import OurProductsCard from "src/components/cards/OurProductsCard";
import PaymentBalance from "src/components/dashboard/student/PaymentBalance";
import PaymentProviderOnboarding from "src/components/dashboard/student/PaymentProviderOnboarding";
import PlatformFeedbackModal from "src/components/feedback/PlatformFeedbackModal";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import Loader from "src/components/Loader";
import StudentCanRegister from "src/components/promotion/banners/StudentCanRegister";
import StudentNeedBasicInformation from "src/components/promotion/banners/StudentNeedBasicInformation";
import ResumeDetail from "src/components/resume/resume-detail/ResumeDetail";
import ResumeSectionTitle from "src/components/resume/resume-detail/ResumeSectionTitle";
import SkillsData from "src/components/skills-data/SkillsData";
import useResume from "src/hooks/api/services/resume/useResume";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";

function DashboardStudentView({ t }: Translation) {
	const auth = useAuth();
	const location = useLocation();
	const { tenant } = useTenant();
	const { resume, actions, status } = useResume({
		rememberKey: auth.hid,
	});

	useEffect(() => {
		if (status === "idle") {
			actions.get(auth.hid);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<Layout container="xxl">
			<div className="flex flex-col md:flex-row gap-6">
				<div className="flex flex-col flex-1 gap-6">
					<StudentCanRegister />
					<PaymentProviderOnboarding />
					{status === "loading" && !resume?.id && (
						<div className="flex flex-col p-8 items-center">
							<Loader />
						</div>
					)}
					{resume?.id && (
						<ResumeDetail
							resume={resume}
							tabs={["profile", "experiences"]}
						>
							<Card contentClassName="gap-6">
								{!(
									auth.address &&
									auth.date_of_birth &&
									auth.telephone
								) && (
									<StudentNeedBasicInformation className="p-3" />
								)}
								<ResumeDetail.Head />
								<ResumeDetail.Tabs editable />
								<ResumeDetail.Tab
									name="profile"
									className="flex flex-col gap-8"
								>
									<ResumeDetail.Editable module="bio">
										<ResumeDetail.Bio />
									</ResumeDetail.Editable>
									{tenant.modules.includes(
										"skills-profile"
									) && (
										<ResumeDetail.Editable module="skills">
											<div className="flex gap-4 flex-wrap justify-between items-center">
												<ResumeSectionTitle>
													Skills
												</ResumeSectionTitle>
												<PlatformFeedbackModal
													type="RESUME_SKILLS"
													data={{
														url: window.location
															.href,
													}}
													trigger={
														<PlatformFeedbackModal.Trigger />
													}
												>
													<PlatformFeedbackModal.Title />
													<PlatformFeedbackModal.Description>
														{t(
															"skills-feedback.description"
														)}
													</PlatformFeedbackModal.Description>
													<PlatformFeedbackModal.Form className="mt-4" />
												</PlatformFeedbackModal>
											</div>
											<SkillsData
												className="flex flex-col flex-1 gap-8"
												data={resume.skills}
											>
												<div className="flex gap-4 justify-center items-center flex-1 flex-col md:flex-row">
													<SkillsData.AreaChart type="area">
														<SkillsData.AreaChartDisplay className="col-span-2 max-h-[400px] w-full max-w-[400px]" />
														<SkillsData.AreaChartLabels />
													</SkillsData.AreaChart>
												</div>
												<div className="flex flex-col gap-8">
													<div className="flex flex-col flex-1 gap-3">
														<div className="flex flex-col">
															<SkillsData.Title type="COMPETENCE" />
															<SkillsData.Description type="COMPETENCE" />
														</div>
														<SkillsData.Skills type="COMPETENCE" />
													</div>
													<div className="flex flex-col flex-1 gap-3">
														<div className="flex flex-col">
															<SkillsData.Title type="KNOWLEDGE" />
															<SkillsData.Description type="KNOWLEDGE" />
														</div>

														<SkillsData.Skills type="KNOWLEDGE" />
													</div>
												</div>
											</SkillsData>
										</ResumeDetail.Editable>
									)}

									<ResumeDetail.Editable module="social-media">
										<ResumeDetail.SocialMedia />
									</ResumeDetail.Editable>
									<ResumeDetail.Badges />
									<ResumeDetail.Reviews variant="border-cards" />
								</ResumeDetail.Tab>
								<ResumeDetail.Tab
									name="experiences"
									className="flex flex-col gap-8"
								>
									<ResumeDetail.Editable module="experiences.education">
										<ResumeDetail.Education variant="timeline" />
									</ResumeDetail.Editable>
									<ResumeDetail.Editable module="experiences.job">
										<ResumeDetail.Work variant="timeline" />
									</ResumeDetail.Editable>
									<ResumeDetail.Reviews variant="border-cards" />
								</ResumeDetail.Tab>
							</Card>
						</ResumeDetail>
					)}
				</div>
				<div className="flex flex-col md:max-w-[400px] w-full gap-6">
					<OurProductsCard />
					<PaymentBalance />
					<div className="">
						<BankCard />
					</div>
				</div>
			</div>
		</Layout>
	);
}

DashboardStudentView.locale = {
	nl: {
		title: "Mijn profiel",
		tabs: {
			profile: "Profiel",
			experiences: "Ervaringen",
		},
		"skills-feedback": {
			description:
				"Vertel ons wat je van de skill resultaten vind, zo kunnen wij de AI in de toekomst verbeteren",
		},
	},
	en: {
		title: "My profile",
		tabs: {
			profile: "Profile",
			experiences: "Experiences",
		},
		"skills-feedback": {
			description:
				"Tell us what you think about the skill results, so we can improve the AI in the future",
		},
	},
};

export default withTranslation(DashboardStudentView);
