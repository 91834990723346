import { useEffect, useState } from "react";
import { ApiListTenant } from "src/api/types";
import Button, { ButtonProps } from "src/components/Button";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Logo from "src/components/Logo";
import useTenants from "src/hooks/api/services/tenants/useTenants";
import useAuth from "src/hooks/selectors/useAuth";
import useAlert from "src/hooks/useAlert";
import { cn } from "src/lib/utils";

interface OurProductsCardProps extends Translation {
	className?: string;
	layout?: "stacked" | "horizontal";
}

const OurProductsCard = ({
	t,
	className,
	layout = "stacked",
}: OurProductsCardProps) => {
	const { tenants, actions } = useTenants();
	const auth = useAuth();

	useEffect(() => {
		if (!tenants.length) {
			actions.list();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Card title={t("title")} className={cn(className)}>
			<div
				className={cn(
					"flex flex-col gap-6",
					layout === "horizontal" && "md:flex-row"
				)}
			>
				{tenants.map((tenant) => (
					<div className="flex flex-col flex-1" key={tenant.slug}>
						<div className="flex items-center gap-4">
							<Logo
								variant="small"
								color="primary"
								className="w-8"
								slug={tenant.slug}
							/>
							<div className="flex flex-col gap-1 flex-1">
								<p className="leading-[100%]">{tenant.name}</p>
								<p className="opacity-70 leading-[120%]">
									{t(
										`descriptions.${tenant.slug}.${auth.type}`
									)}
								</p>
							</div>
							{!tenant.has_access && (
								<JoinTenantButton {...{ tenant }}>
									{t("join")}
								</JoinTenantButton>
							)}
						</div>
					</div>
				))}
			</div>
		</Card>
	);
};

OurProductsCard.locale = {
	nl: {
		title: "Onze producten",
		descriptions: {
			getcrewdone: {
				student: "Werk op de dikste festivals",
				company: "Schakel jongeren in voor jouw event",
			},
			getjobsdone: {
				student: "Doe opdrachten die passen bij je skills",
				company: "Schakel (Young) Professionals in voor jouw bedrijf",
			},
			getrecruitmentdone: {
				student: "Vind jouw volgende job",
				company: "Vind (Young) Professionals voor al je vacatures",
			},
		},
		join: "Join",
	},
	en: {},
};

interface JoinTenantButtonProps extends ButtonProps, Translation {
	tenant: ApiListTenant;
}

const JoinTenantButton = withTranslation(
	({ t, tenant, children, ...props }: JoinTenantButtonProps) => {
		const [isLoading, setIsLoading] = useState(false);
		const [sendAlert] = useAlert();
		const { actions } = useTenants();
		const onClick = () => {
			sendAlert({
				type: "confirm",
				title: t("confirm.title"),
				text: t("confirm.text", {
					name: tenant.name,
				}),
				onConfirm: async () => {
					setIsLoading(true);
					try {
						const data = await actions.join(
							tenant,
							"FROM_OUR_PRODUCTS"
						);
						window.location = data.redirect_url;
					} finally {
						setIsLoading(false);
					}
				},
			});
		};
		return (
			<div data-theme={tenant.slug}>
				<Button
					type="primary"
					xsmall
					{...props}
					{...{ onClick }}
					loading={isLoading}
				>
					{children}
				</Button>
			</div>
		);
	},
	{
		nl: {
			confirm: {
				title: "Wil je je account inschakelen?",
				text: "Wanneer je op bevestigen klikt, wordt je account bij {{name}} ingeschakeld en word je doorverwezen.",
			},
		},
	}
);

export default withTranslation(OurProductsCard);
