import { useEffect, useState } from "react";
import { Address } from "src/api/types";
import Input from "src/components/form/Input";
import AddressItem from "src/components/forms/AddressForm/v2/AddressItem";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useAddressAutocomplete from "src/hooks/api/useAddressAutocomplete";
import { cn } from "src/lib/utils";

interface AddressFormProps extends Translation {
	address: Address;
	onChange: (address: Address) => void;
	defaultMode?: "search" | "manual";
	className?: string;
	children?: (mode: "search" | "manual") => any;
}

const AddressForm = ({
	t,
	address,
	onChange,
	defaultMode = "search",
	className,
	children,
}: AddressFormProps) => {
	const [mode, setMode] = useState<"search" | "manual">(defaultMode);
	const [search, setSearch] = useState<string>();
	const { places, fetchingPlaceId, actions } = useAddressAutocomplete({
		search,
	});

	useEffect(() => {
		if (defaultMode && defaultMode !== mode) {
			setMode(defaultMode);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultMode]);

	const getInput = (name: keyof Address) => ({
		noMarginBottom: true,
		placeholder: t(`${name}.placeholder`),
		name,
		value: (address || ({} as any))[name] || "",
		handleChange: (e: any) =>
			onChange({ ...address, [name]: e?.target?.value }),
	});

	return (
		<div className={cn("flex flex-col gap-3", className)}>
			{mode === "manual" && (
				<div className="flex flex-col gap-4">
					<div className="flex gap-4">
						<Input {...getInput("street")} />
						<div className="max-w-20">
							<Input {...getInput("house_number")} />
						</div>
					</div>
					<Input {...getInput("zipcode")} />
					<Input {...getInput("city")} />
				</div>
			)}
			{mode === "search" && (
				<>
					<Input
						className="rounded-none"
						value={search}
						handleChange={(e) => setSearch(e?.target?.value)}
						name="search"
						placeholder={t("search.help")}
						noMarginBottom
					/>

					{!!places.length && (
						<div className="flex flex-col gap-1">
							<p className="text-placeholder mb-1">
								<small className="mr-1">
									<i className="far fa-map-marker"></i>
								</small>
								{t("places.title")}
							</p>

							{(places || []).map((item) => (
								<AddressItem
									key={item.id}
									description={item.description}
									onClick={() =>
										actions
											.getAddress(item.id)
											.then((data) => {
												onChange(data);
												setMode("manual");
											})
									}
									fetching={fetchingPlaceId === item.id}
									{...{ search }}
								/>
							))}
						</div>
					)}
				</>
			)}

			{children && children(mode)}

			<span
				onClick={() => setMode(mode === "manual" ? "search" : "manual")}
				className="cursor-pointer flex gap-1 items-center select-none opacity-50 hover:opacity-100 transition-all text-sm"
			>
				{/* <small>
					<i
						className={`far ${
							mode === "manual" ? "fa-search" : "fa-pencil"
						}`}
					></i>
				</small> */}
				{t(`${mode === "manual" ? "search" : "manual"}.button`)}
			</span>
		</div>
	);
};

AddressForm.locale = {
	nl: {
		search: {
			label: "Adres",
			placeholder: "Adres",
			help: "Bijv: Zonnebloemstraat 17",
			button: "Adres zoeken",
		},
		places: {
			title: "Adressen",
		},
		street: {
			placeholder: "Straat",
			description: "Bijv: Zonnebloemstraat 17",
		},
		house_number: {
			placeholder: "Huisnummer",
		},
		zipcode: {
			placeholder: "Postcode",
		},
		city: {
			placeholder: "Stad",
		},
		manual: {
			button: "Handmatig adres invullen",
		},
	},
	en: {
		search: {
			label: "Address",
			placeholder: "Address",
			help: "E.g.: Sunflower Street 17",
			button: "Search Address",
		},
		places: {
			title: "Addresses",
		},
		street: {
			placeholder: "Street",
			description: "E.g.: Sunflower Street 17",
		},
		house_number: {
			placeholder: "House Number",
		},
		zipcode: {
			placeholder: "Postal Code",
		},
		city: {
			placeholder: "City",
		},
		manual: {
			button: "Enter Address Manually",
		},
	},
};

export default withTranslation(AddressForm);
