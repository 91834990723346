import { useEffect } from "react";
import Button from "src/components/Button";
import Draggable, { DragChangeEvent } from "src/components/draggable";
import EmptyState from "src/components/EmptyState";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import ProfileSettingsLayout from "src/components/layout/profile-settings-layout/ProfileSettingsLayout";
import UnknownTeamCard from "src/components/profile/team/UnknownTeamCard";
import UsersTeamCard from "src/components/profile/team/UsersTeamCard";
import InviteUserButton from "src/components/profile/user/InviteUserButton";
import TotalUsersCount from "src/components/profile/user/TotalUsersCount";
import useTeams from "src/hooks/api/services/users/useTeams";
import useUsers from "src/hooks/api/services/users/useUsers";

function UsersView({ t }: Translation) {
	const { users, actions: userActions } = useUsers();
	const { teams, actions: teamActions, status } = useTeams();

	const onChange = (event: DragChangeEvent) => {
		if (!event.to.toString().includes("TEAM_")) return;
		const teamHid = event.to.toString().replace("TEAM_", "");
		const userId = Number(event.id);
		if (!userId) return;
		const team = teams?.find((team) => team.hid === teamHid);
		userActions.update(
			{
				id: userId,
				teams: [team?.slug],
			},
			{ updateList: true }
		);
	};

	useEffect(() => {
		teamActions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ProfileSettingsLayout title={t("title")}>
			<div className="flex items-center justify-between gap-4">
				<TotalUsersCount />
				<div className="flex items-center gap-3">
					<Button
						type="dark"
						to={{ modal: "/profile/users/teams/create" }}
					>
						<i className="far fa-plus mr-2"></i>
						{t("buttons.team")}
					</Button>
				</div>
			</div>
			{(status !== "loading" || users.length) && (
				<Draggable {...{ onChange }}>
					<div className="flex flex-col-reverse md:flex-row flex-1 gap-4">
						<div className="flex-1 flex flex-col gap-4 relative">
							{teams.length === 0 && (
								<div className="flex flex-1  flex-col">
									<EmptyState.Overlay className="p-4">
										<EmptyState.Title>
											{t("empty.title")}
										</EmptyState.Title>
										<EmptyState.Description>
											{t("empty.description")}
										</EmptyState.Description>
										<Button
											to={{
												modal: "/profile/users/teams/create",
											}}
											className="mt-4"
											type="dark"
										>
											<i className="far fa-plus mr-2"></i>
											<span>{t("empty.button")}</span>
										</Button>
									</EmptyState.Overlay>
								</div>
							)}
							{teams.map((team) => (
								<UsersTeamCard
									{...{ team }}
									droppable
									key={`team-${team.hid}`}
								/>
							))}
						</div>
						<div className="flex flex-col gap-4 lg:w-[360px]">
							<UnknownTeamCard
								droppable
								content={{
									className: "md:flex-col",
								}}
							>
								<div className="flex flex-1 w-full">
									<InviteUserButton className="md:flex-1" />
								</div>
							</UnknownTeamCard>
						</div>
					</div>
				</Draggable>
			)}
		</ProfileSettingsLayout>
	);
}

UsersView.locale = {
	nl: {
		title: "Teams & Gebruikers",
		buttons: {
			team: "Team",
			user: "Gebruiker",
		},
		empty: {
			title: "Maak je eerste team aan",
			description:
				"Door het creëren van diverse teams wordt duidelijk welke vaardigheden beschikbaar zijn binnen jouw bedrijf en welke teams moeilijkheden ondervinden met hun capaciteit.",
			button: "Team maken",
		},
	},
	en: {
		title: "Teams & Users",
		buttons: {
			team: "Team",
			user: "User",
		},
		empty: {
			title: "Create your first team",
			description:
				"By creating various teams, you'll gain insight into the skills available within your company and identify which teams are facing capacity challenges.",
			button: "Create Team",
		},
	},
};

export default withTranslation(UsersView);
