import { ApiRegistration } from "src/api/types";
import PlatformFeedbackModal from "src/components/feedback/PlatformFeedbackModal";
import SkillSelector from "src/components/form/skill-selector/SkillSelector";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import RegistrationDetails from "src/components/tasks/panes/registration/RegistrationDetails";
import RegistrationSkillsMatchItem from "src/components/tasks/panes/registration/RegistrationSkillsMatchItem";
import useAuth from "src/hooks/selectors/useAuth";

interface RegistrationSkillsMatchProps extends Translation {
	registration: ApiRegistration;
}

const RegistrationSkillsMatch = ({
	t,
	registration,
}: RegistrationSkillsMatchProps) => {
	const auth = useAuth();
	const score = registration?.score;
	if (!score) {
		return (
			<div className="flex flex-col gap-6 ">
				<div className="flex flex-col gap-1 p-3">
					<strong>{t("empty.title")}</strong>
					<p className="opacity-80">{t("empty.description")}</p>
				</div>
				{registration?.user?.variant === "DEFAULT" && (
					<RegistrationDetails {...{ registration }} />
				)}
			</div>
		);
	}
	const data = score.data;
	return (
		<div className="flex flex-col gap-6">
			<div className="flex flex-col p-4">
				<div className="flex justify-between items-start gap-4 flex-wrap">
					<div className="flex gap-6 items-end">
						<div className="bg-primary py-4 px-6 rounded-md w-32 text-center">
							<h1 className="text-aqua-light">{score.score}</h1>
						</div>
						<div className="flex flex-col flex-1">
							<p>{t("match-score")}</p>

							<p className="opacity-80">
								{t("match-score-description")}
							</p>
						</div>
					</div>
					<PlatformFeedbackModal
						type="REGISTRATION_SCORE"
						data={{
							url: window.location.href,
						}}
						trigger={<PlatformFeedbackModal.Trigger />}
					>
						<PlatformFeedbackModal.Title />
						<PlatformFeedbackModal.Description>
							{t("skills-feedback.description")}
						</PlatformFeedbackModal.Description>
						<PlatformFeedbackModal.Form className="mt-4" />
					</PlatformFeedbackModal>
				</div>
			</div>
			{data.CONNECTION && (
				<RegistrationSkillsMatchItem
					score={data.CONNECTION.score}
					title={t(`items.${auth.type}.CONNECTION.title`)}
					description={t(`items.${auth.type}.CONNECTION.description`)}
				/>
			)}

			{data.DIRECT_SKILLS.score > 0 && (
				<RegistrationSkillsMatchItem
					score={data.DIRECT_SKILLS.score}
					title={t(`items.${auth.type}.DIRECT_SKILLS.title`)}
					description={t(
						`items.${auth.type}.DIRECT_SKILLS.description`
					)}
				>
					{data.DIRECT_SKILLS.skills && (
						<SkillSelector
							defaultSkills={data.DIRECT_SKILLS.skills}
						>
							<SkillSelector.Results
								variant="display"
								itemClassName="bg-primary-300 text-primary border-transparent"
							/>
						</SkillSelector>
					)}
				</RegistrationSkillsMatchItem>
			)}

			{data.SIMILAR_SKILLS.score > 0 && (
				<RegistrationSkillsMatchItem
					score={data.SIMILAR_SKILLS.score}
					title={t(`items.${auth.type}.SIMILAR_SKILLS.title`)}
					description={t(
						`items.${auth.type}.SIMILAR_SKILLS.description`
					)}
				>
					{data.SIMILAR_SKILLS.skills && (
						<SkillSelector
							defaultSkills={data.SIMILAR_SKILLS.skills}
						>
							<SkillSelector.Results
								variant="display"
								itemClassName="bg-primary-300 text-primary border-transparent"
							/>
						</SkillSelector>
					)}
				</RegistrationSkillsMatchItem>
			)}

			{data.CATEGORY_MATCH.score > 0 && (
				<RegistrationSkillsMatchItem
					score={data.CATEGORY_MATCH.score}
					title={t(`items.${auth.type}.CATEGORY_MATCH.title`)}
					description={t(
						`items.${auth.type}.CATEGORY_MATCH.description`
					)}
				>
					{data.CATEGORY_MATCH.categories.length > 0 && (
						<SkillSelector
							defaultSkills={data.CATEGORY_MATCH.categories}
						>
							<SkillSelector.Results
								variant="display"
								itemClassName="bg-primary-300 text-primary border-transparent"
							/>
						</SkillSelector>
					)}
				</RegistrationSkillsMatchItem>
			)}

			{data.DIRECT_EDUCATIONS.score > 0 && (
				<RegistrationSkillsMatchItem
					score={data.DIRECT_EDUCATIONS.score}
					title={t(`items.${auth.type}.DIRECT_EDUCATIONS.title`)}
					description={t(
						`items.${auth.type}.DIRECT_EDUCATIONS.description`
					)}
				>
					{data.DIRECT_EDUCATIONS.educations?.length > 0 && (
						<ul className="flex flex-col gap-1.5">
							{data.DIRECT_EDUCATIONS.educations.map(
								(education) => (
									<li
										className="flex gap-2 items-center"
										key={education.slug}
									>
										<div className="w-4 h-1 rounded-md bg-primary opacity-50"></div>
										<p>
											<span className="opacity-50">
												{`${education.level} `}
											</span>
											<span>{education.name}</span>
										</p>
									</li>
								)
							)}
						</ul>
					)}
				</RegistrationSkillsMatchItem>
			)}

			{data.DIRECT_OCCUPATIONS.score > 0 && (
				<RegistrationSkillsMatchItem
					score={data.DIRECT_OCCUPATIONS.score}
					title={t(`items.${auth.type}.DIRECT_OCCUPATIONS.title`)}
					description={t(
						`items.${auth.type}.DIRECT_OCCUPATIONS.description`
					)}
				>
					{data.DIRECT_OCCUPATIONS.occupations?.length > 0 && (
						<ul className="flex flex-col gap-1.5">
							{data.DIRECT_OCCUPATIONS.occupations.map(
								(occupation) => (
									<li
										className="flex gap-2 items-center"
										key={occupation.slug}
									>
										<div className="w-4 h-1 rounded-md bg-primary opacity-50"></div>
										<p>{occupation.name}</p>
									</li>
								)
							)}
						</ul>
					)}
				</RegistrationSkillsMatchItem>
			)}

			<RegistrationDetails {...{ registration }} />
		</div>
	);
};

RegistrationSkillsMatch.locale = {
	nl: {
		"match-score": "Skills match score",
		"match-score-description":
			"De score is opgebouwd uit punten die per onderdeel te behalen zijn",
		empty: {
			title: "Geen skills report gevonden",
			description:
				"Er is nog geen skills report gevonden voor deze aanmelding.",
		},
		"skills-feedback": {
			description:
				"Vertel ons wat je van de skill / score vind, zo kunnen wij de AI in de toekomst verbeteren",
		},
		items: {
			company: {
				CONNECTION: {
					title: "Connectie",
					description:
						"Je hebt al met deze kandidaat gewerkt, daardoor zou deze kandidaat een grotere kans van slagen hebben.",
				},
				DIRECT_SKILLS: {
					title: "Directe skills match",
					description:
						"Deze kandidaat heeft directe matches op de volgende skills.",
				},
				SIMILAR_SKILLS: {
					title: "Vergelijkbare skills",
					description:
						"Deze kandidaat heeft skills die vergelijkbaar zijn aan die nodig zijn voor de opdracht.",
				},
				DIRECT_EDUCATIONS: {
					title: "Passende opleidingen",
					description:
						"Deze kandidaat heeft opleiding(en) gedaan die direct matchen met de opdracht.",
				},
				DIRECT_OCCUPATIONS: {
					title: "Passende werkervaringen",
					description:
						"Deze kandidaat heeft werkervaring(en) gedaan die direct matchen met de opdracht.",
				},
				CATEGORY_MATCH: {
					title: "Overeenkomende categorieën",
					description:
						"Deze kandidaat heeft skills categorieën die overeenkomen met de categorieën van de opdracht.",
				},
			},
			student: {
				CONNECTION: {
					title: "Connectie",
					description:
						"Doordat je al eerder met deze opdrachtgever hebt gewerkt, heb je een grotere kans van slagen.",
				},
				DIRECT_SKILLS: {
					title: "Directe skills match",
					description:
						"Je hebt directe matches op de volgende skills.",
				},
				SIMILAR_SKILLS: {
					title: "Vergelijkbare skills",
					description:
						"Je hebt skills die vergelijkbaar zijn aan die nodig zijn voor de opdracht.",
				},
				DIRECT_EDUCATIONS: {
					title: "Passende opleidingen",
					description:
						"Je hebt opleiding(en) gedaan die direct matchen met de opdracht.",
				},
				DIRECT_OCCUPATIONS: {
					title: "Passende werkervaringen",
					description:
						"Je hebt werkervaring(en) gedaan die direct matchen met de opdracht.",
				},
				CATEGORY_MATCH: {
					title: "Overeenkomende categorieën",
					description:
						"Je hebt skills categorieën die overeenkomen met de categorieën van de opdracht.",
				},
			},
		},
	},
	en: {
		"match-score": "Skills match score",
		"match-score-description":
			"The score is based on points that can be earned in each section.",
		empty: {
			title: "No skills report found",
			description:
				"No skills report has been found for this application.",
		},
		"skills-feedback": {
			description:
				"Tell us what you think about the skill / score, so we can improve the AI in the future",
		},
		items: {
			company: {
				CONNECTION: {
					title: "Connection",
					description:
						"You've already worked with this candidate, which increases their likelihood of success.",
				},
				DIRECT_SKILLS: {
					title: "Direct skills match",
					description:
						"This candidate has direct matches on the following skills.",
				},
				SIMILAR_SKILLS: {
					title: "Similar skills",
					description:
						"This candidate has skills similar to those needed for the task.",
				},
				DIRECT_EDUCATIONS: {
					title: "Relevant education",
					description:
						"This candidate has education(s) that directly match the task.",
				},
				DIRECT_OCCUPATIONS: {
					title: "Relevant work experience",
					description:
						"This candidate has work experience(s) that directly match the task.",
				},
				CATEGORY_MATCH: {
					title: "Matching categories",
					description:
						"This candidate has skills categories that match the task's categories.",
				},
			},
			student: {
				CONNECTION: {
					title: "Connection",
					description:
						"You've already worked with this candidate, which increases their likelihood of success.",
				},
				DIRECT_SKILLS: {
					title: "Direct skills match",
					description:
						"You have direct matches on the following skills.",
				},
				SIMILAR_SKILLS: {
					title: "Similar skills",
					description:
						"You have skills similar to those needed for the task.",
				},
				DIRECT_EDUCATIONS: {
					title: "Relevant education",
					description:
						"You have education(s) that directly match the task.",
				},
				DIRECT_OCCUPATIONS: {
					title: "Relevant work experience",
					description:
						"You have work experience(s) that directly match the task.",
				},
				CATEGORY_MATCH: {
					title: "Matching categories",
					description:
						"You have skills categories that match the task's categories.",
				},
			},
		},
	},
};

export default withTranslation(RegistrationSkillsMatch);
