import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { ApiSubscriptionPlanInterval } from "src/api/types";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout, {
	AuthLayoutContainer,
} from "src/components/layout/auth-layout/AuthLayout";
import Link from "src/components/Link";
import Loader from "src/components/Loader";
import SubscriptionPlanSelected from "src/components/subscription/SubscriptionPlanSelected";
import useSubscriptionCheckoutSession from "src/hooks/api/services/session/useSubscriptionCheckoutSession";
import usePlans from "src/hooks/api/usePlans";
import useQueryString from "src/hooks/useQueryString";

const SignupCompanySubscriptionCheckoutView = ({ t }: Translation) => {
	const params = useParams();
	const qs = useQueryString();
	const interval = (
		qs.interval?.toString() || "monthly"
	).toUpperCase() as ApiSubscriptionPlanInterval;
	const {
		session,
		actions: sessionActions,
		status: sessionStatus,
	} = useSubscriptionCheckoutSession();
	const sessionFailed = qs.error === "mandate-failed";
	const { plans, status } = usePlans();
	const plan = plans.find(
		(item) => item.slug === params.slug && item.interval === interval
	);

	useEffect(() => {
		if (qs.session) {
			sessionActions.find(qs.session.toString());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!plan && status === "idle" && plans.length > 0) {
		return <Navigate to="/signup/company" />;
	}

	if (qs.session && sessionStatus !== "idle") {
		return (
			<AuthLayout variant="flat" actions={["contact-sales"]}>
				<AuthLayoutContainer className="flex flex-col flex-1 justify-center items-center">
					<Loader />
				</AuthLayoutContainer>
			</AuthLayout>
		);
	}

	return (
		<AuthLayout variant="flat" actions={["contact-sales"]}>
			<AuthLayoutContainer>
				{plan && (
					<SubscriptionPlanSelected
						{...{ plan, interval }}
						header={
							sessionFailed &&
							session?.id && (
								<div className="flex flex-col gap-2">
									<p>{t("session-failed")}</p>
								</div>
							)
						}
						sessionId={session?.id}
						className="md:rounded-md mx-auto w-full md:mt-8 mt-4 max-w-xl"
						showIntervalSwitch={false}
						showAnimations={false}
						layout="horizontal"
					/>
				)}
				<div className="flex justify-center items-center mt-8">
					<Link className="opacity-50" to="/signup/company">
						{t("back")}
					</Link>
				</div>
			</AuthLayoutContainer>
		</AuthLayout>
	);
};

SignupCompanySubscriptionCheckoutView.locale = {
	nl: {
		back: "Terug naar abonnementen",
		"session-failed":
			"De koppeling met je bank is mislukt. Probeer het opnieuw.",
	},
	en: {
		back: "Back to subscriptions",
		"session-failed":
			"The connection with your bank failed. Please try again.",
	},
};

export default withTranslation(SignupCompanySubscriptionCheckoutView);
